<template>
  <Portlet
    :title="`${ $t('busiestInstallations') } (${ days } ${$t('days')})`"
    icon="hdd"
  >
    <LoadingPlaceholder v-if="loading" />
    <div
      v-else
      class="busiestInstallations"
    >
      <div class="m-form__group form-group">
        <div class="m-radio-inline">
          <label class="m-radio">
            <input
              v-model="days"
              type="radio"
              :name="'bussReportChartRadios'"
              value="180"
            >180 {{ $t('days') }}
            <span />
          </label>
          <label class="m-radio">
            <input
              v-model="days"
              type="radio"
              :name="'bussReportChartRadios'"
              value="60"
            >60 {{ $t('days') }}
            <span />
          </label>
          <label class="m-radio">
            <input
              v-model="days"
              type="radio"
              :name="'bussReportChartRadios'"
              value="30"
            >30 {{ $t('days') }}
            <span />
          </label>
          <label class="m-radio">
            <input
              v-model="days"
              type="radio"
              :name="'bussReportChartRadios'"
              value="7"
            >7 {{ $t('days') }}
            <span />
          </label>
          <label class="m-radio">
            <input
              v-model="days"
              type="radio"
              :name="'bussReportChartRadios'"
              value="3"
            >3 {{ $t('days') }}
            <span />
          </label>
        </div>
      </div>
      <table
        v-if="hasBigReports"
        class="defaultTable"
      >
        <thead>
          <tr>
            <th>{{ $t('installation') }}</th>
            <th>{{ $t('count') }}</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(report, index) in reports">
            <tr
              v-if="report.count > 10"
              :key="`historyOverview-reports-tableRow-${ index }`"
            >
              <td>
                <router-link :to="{ path: `/installation/${ report.installationId }` }">
                  {{ report.installationName }}
                </router-link>
              </td>
              <td>{{ report.count }}</td>
            </tr>
          </template>
        </tbody>
      </table>

      <p v-else>
        {{ $t('noDataAvailable') }}
      </p>
    </div>
  </Portlet>
</template>

<script>
export default {
  name: 'BusiestInstallations',
  data () {
    return {
      reports: {},
      days: 3,
      loading: false
    }
  },
  computed: {
    hasBigReports () {
      if (this.reports == null) {
        return false;
      }
      if (this.reports.length == null) {
        return false;
      }
      if (this.reports.length == 0) {
        return false;
      }
      return true;
    },
  },
  watch: {
    days () {
      this.getCommentReport();
    }
  },
  created () {
    this.getCommentReport();
  },
  methods: {
    getCommentReport () {
      this.loading = true;
      this.axios.get(`/Installation/GetInstallationCommentReport?days=${ this.days }`)
        .then((response) => {
          this.reports = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>
